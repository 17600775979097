<template>
  <div>
    <span
      ><img style="width: 100%" src="../assets/image/home/banner.jpg"
    /></span>
  </div>

  <div style="margin-top: 100px; padding-right: 270px; padding-left: 270px">
    <el-row :gutter="20" style="margin-bottom: 50px; text-align: center">
      <el-col>
        <p>联系我们：</p>
        <p>400-691-6788</p>
        <p>18053702333</p>
        <div style="width:350px;margin-left:510px">
          <img src="../assets/rwm.png" class="image">
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "About",
  components: {
    // HelloWorld,
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/index.css";
.describe {
  padding-left: 20px;
  width: 1020px;
  text-align: left;
  border-bottom: 1px solid #a9a9a9;
}
.describe-p {
  line-height: 30px;
}
.describes {
  padding-left: 20px;
  width: 1020px;
  text-align: left;
  border-bottom: 1px solid #a9a9a9;
  background: #098af3;
}
p{
  font-size: 20px;
}
</style>